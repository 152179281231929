import { chakra, useMultiStyleConfig } from "@chakra-ui/react";
import { useTenClicksToStartEditorTools } from "../EditorTools/EditorTools";

export const MaintenanceSplash: React.FC = () => {
	const styles = useMultiStyleConfig("MaintenanceSplash");
	const handleCopyrightClick = useTenClicksToStartEditorTools();

	return (
		// eslint-disable-next-line react/jsx-no-literals
		<chakra.div onClick={handleCopyrightClick} __css={styles.wrapper}>
			🚧
		</chakra.div>
	);
};

// 🔬 TBD: Please evaluate
