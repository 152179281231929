import Head from "next/head";
import React from "react";
import { EditorTools } from "src/components/EditorTools/EditorTools";
import { MaintenanceSplash } from "src/components/MaintenanceSplash/MaintenanceSplash";
import { SharedContext } from "src/components/SharedContext";
import type { SiteSettings } from "src/lib/ssr/utils";

export const MaintenanceMode: React.FC<{
	siteSettings: SiteSettings;
	isPreview: boolean;
}> = ({ isPreview, siteSettings }) => (
	<SharedContext siteSettings={siteSettings}>
		<Head>
			{/* eslint-disable-next-line react/jsx-no-literals*/}
			<title>🚧</title>
		</Head>
		<MaintenanceSplash />
		<EditorTools isPreview={isPreview} />
	</SharedContext>
);

// 🔬 TBD: Please evaluate
