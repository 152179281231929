import { VisuallyHidden } from "@chakra-ui/react";
import React from "react";
import { AuthGuard } from "src/components/AuthGuard/AuthGuard";
import { EditorTools } from "src/components/EditorTools/EditorTools";
import { Footer } from "src/components/Footer/Footer";
import { Header } from "src/components/Header/Header";
import { HeadingLevelBoundary } from "src/components/Heading/Heading";
import { Layout } from "src/components/Layout/Layout";
import { MaintenanceMode } from "src/components/MaintenanceMode/MaintenanceMode";
import { ProductOverview } from "src/components/ProductOverview/ProductOverview";
import { SharedContext } from "src/components/SharedContext";
import { SharedPageHead } from "src/components/SharedPageHead";
import { SharedProps } from "src/lib/ssr/getStaticProps/getStaticProps.base.service";
import { ProductFamilyPageProps } from "src/lib/ssr/getStaticProps/getStaticProps.pageOrProductFamily.service";
import { showMaintenanceMode } from "src/lib/ssr/utils";
import { createBreadcrumbs } from "src/lib/utils";
import { SectionRenderer } from "src/sections/SectionRenderer";

type SectionType =
	| NonNullable<
			ProductFamilyPageProps["insuranceProductFamily"]
	  >["topContent"][number]["__typename"]
	| NonNullable<
			ProductFamilyPageProps["insuranceProductFamily"]
	  >["bottomContent"][number]["__typename"];

const sectionsWithH1: Array<SectionType> = ["HeroRecord"];

export const ProductFamilyPage: React.FC<
	SharedProps<ProductFamilyPageProps>
> = ({
	isPreview,
	canonical,
	highlightColor,
	otherLanguages,
	hasSiblingBrands,
	products,
	insuranceProductFamily,
	...sharedProps
}) => {
	const content = [
		...insuranceProductFamily.topContent,
		...insuranceProductFamily.bottomContent,
	];

	const breadcrumbsList = createBreadcrumbs(
		insuranceProductFamily.breadcrumb,
	);

	const hasActualH1 = content.some((section) =>
		sectionsWithH1.includes(section.__typename),
	);

	const showMaintenance = showMaintenanceMode(
		sharedProps.siteSettings,
		isPreview,
	);

	if (showMaintenance) {
		return (
			<MaintenanceMode
				siteSettings={sharedProps.siteSettings}
				isPreview={isPreview}
			/>
		);
	}

	const customOrder = insuranceProductFamily.customProductOrder.map(
		({ name, productGroupName }) => ({
			name: name.replaceAll("|", ""),
			productGroupName: productGroupName?.replaceAll("|", ""),
		}),
	);

	return (
		<SharedContext {...sharedProps}>
			<AuthGuard accessLevels={["guest"]} redirectOnFail>
				<Layout
					highlightColor={highlightColor}
					header={
						<Header
							breadcrumbsList={breadcrumbsList}
							links={insuranceProductFamily.header?.links}
							logoData={
								insuranceProductFamily.header?.headerLogo ??
								undefined
							}
							otherLanguages={otherLanguages}
							hasSiblingBrands={hasSiblingBrands}
						/>
					}
					footer={
						<Footer
							footerSettings={
								sharedProps.siteSettings.footerSettings
							}
						/>
					}
				>
					<SharedPageHead
						metaTags={insuranceProductFamily._seoMetaTags}
						canonical={canonical}
					/>
					{!hasActualH1 && (
						<VisuallyHidden>
							<h1>
								{insuranceProductFamily.seo?.title ??
									insuranceProductFamily.name}
							</h1>
						</VisuallyHidden>
					)}
					<SectionRenderer
						sections={{
							topSections: insuranceProductFamily.topContent,
							bottomSections:
								insuranceProductFamily.bottomContent,
						}}
					>
						<HeadingLevelBoundary>
							<ProductOverview
								products={products}
								customOrder={customOrder}
								introAlignment="center"
							/>
						</HeadingLevelBoundary>
					</SectionRenderer>
				</Layout>
				<EditorTools isPreview={Boolean(isPreview)} />
			</AuthGuard>
		</SharedContext>
	);
};

// 🔬 TBD
