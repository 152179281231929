import merge from "lodash/merge";
import React from "react";
import { getPagesForGetStaticPaths } from "src/lib/ssr/getDataForGetStaticPaths";
import { SharedProps } from "src/lib/ssr/getStaticProps/getStaticProps.base.service";
import {
	GetStaticPropsPageOrProductFamilyService,
	PageProps,
	ProductFamilyPageProps,
} from "src/lib/ssr/getStaticProps/getStaticProps.pageOrProductFamily.service";
import { isRecord } from "src/lib/utils";
import { ProductFamilyPage } from "src/templates/ProductFamilyPage";
import { Page } from "../../../templates/Page";
import type { GetStaticPaths, GetStaticProps, NextPage } from "next";

type Props = PageProps | ProductFamilyPageProps;

const isProductFamilyPage = (
	maybeProductFamilyPage: unknown,
): maybeProductFamilyPage is ProductFamilyPageProps => {
	return (
		isRecord(maybeProductFamilyPage) &&
		"insuranceProductFamily" in maybeProductFamilyPage
	);
};

const PageOrFamilyCluster: NextPage<SharedProps<Props>> = (props) => {
	if (isProductFamilyPage(props)) {
		return <ProductFamilyPage {...props} />;
	}

	return <Page {...props} />;
};

export const getStaticProps: GetStaticProps<
	SharedProps<Props>,
	{ brandSlug: string; pageOrProductFamilySlug: string }
> = async (ctx) => {
	const pageOrProductFamilySlug = (
		ctx.params?.pageOrProductFamilySlug ??
		GetStaticPropsPageOrProductFamilyService.rootSlug
	).toString();

	const merged = merge(ctx, { params: { pageOrProductFamilySlug } });

	const propsService = new GetStaticPropsPageOrProductFamilyService(merged);

	await propsService.init();

	const result = await propsService.get();

	return result;
};

export const getStaticPaths: GetStaticPaths = async () => {
	const pages = await getPagesForGetStaticPaths();

	const paths = pages
		.filter(({ slug }) => slug !== "index")
		.map(({ slug: pageOrProductFamilySlug, brand }) => ({
			params: { pageOrProductFamilySlug, brandSlug: brand.slug },
		}));

	return {
		fallback: "blocking",
		paths,
	};
};

export default PageOrFamilyCluster;

// 🔬 e2e: pages.spec.ts
