import settings from "funk-experts.config.json";
import merge from "lodash/merge";
import { SharedProps } from "src/lib/ssr/getStaticProps/getStaticProps.base.service";
import {
	GetStaticPropsPageOrProductFamilyService,
	PageProps,
} from "src/lib/ssr/getStaticProps/getStaticProps.pageOrProductFamily.service";
import Page from "./[pageOrProductFamilySlug]";
import type { GetStaticPaths, GetStaticProps } from "next";

export const getStaticProps: GetStaticProps<
	SharedProps<PageProps>,
	{ brandSlug: string }
> = async (ctx) => {
	const merged = merge(ctx, { params: { pageOrProductFamilySlug: "index" } });

	const propsService = new GetStaticPropsPageOrProductFamilyService(merged);

	await propsService.init();

	const result = await propsService.get();

	return result;
};

export const getStaticPaths: GetStaticPaths = async () => {
	const paths = settings.brands.map((brand) => ({
		params: { slug: "index", brandSlug: brand.slug },
	}));

	return {
		fallback: "blocking",
		paths,
	};
};

export default Page;

// 🔬 pages.spec.ts
