import { VisuallyHidden } from "@chakra-ui/react";
import React from "react";
import { AuthGuard, isRole } from "src/components/AuthGuard/AuthGuard";
import { EditorTools } from "src/components/EditorTools/EditorTools";
import { Footer } from "src/components/Footer/Footer";
import { Header } from "src/components/Header/Header";
import { Layout } from "src/components/Layout/Layout";
import { MaintenanceMode } from "src/components/MaintenanceMode/MaintenanceMode";
import { SharedContext } from "src/components/SharedContext";
import { SharedPageHead } from "src/components/SharedPageHead";
import { SharedProps } from "src/lib/ssr/getStaticProps/getStaticProps.base.service";
import { PageProps } from "src/lib/ssr/getStaticProps/getStaticProps.pageOrProductFamily.service";
import { showMaintenanceMode } from "src/lib/ssr/utils";
import { createBreadcrumbs } from "src/lib/utils";
import { SectionRenderer } from "src/sections/SectionRenderer";

type SectionType = NonNullable<
	PageProps["page"]
>["content"][number]["__typename"];

// These sections have a (we assume dark) background. If they appear as first item we want the header to transition.
const sectionsWithBackground: Array<SectionType> = [
	"PersonaRecord",
	"HeroRecord",
	"HeroSliderRecord",
];

const sectionsWithH1: Array<SectionType> = [
	"HeroRecord",
	"PremiumCalcHeroRecord",
];

export const Page: React.FC<SharedProps<PageProps>> = ({
	page,
	isPreview,
	canonical,
	highlightColor: highlightColorRaw,
	otherLanguages,
	hasSiblingBrands,
	...sharedProps
}) => {
	const breadcrumbsList = createBreadcrumbs(page.breadcrumb);

	const hasActualH1 = page.content.some((section) =>
		sectionsWithH1.includes(section.__typename),
	);

	const headerShouldTransition =
		!breadcrumbsList.length &&
		page.content[0] &&
		sectionsWithBackground.includes(page.content[0].__typename);

	const showMaintenance = showMaintenanceMode(
		sharedProps.siteSettings,
		isPreview,
	);

	const customHeaderLinks = page.targetGroup?.header?.links;

	if (showMaintenance) {
		return (
			<MaintenanceMode
				siteSettings={sharedProps.siteSettings}
				isPreview={isPreview}
			/>
		);
	}

	const targetGroupCtx = page.targetGroup && {
		id: page.targetGroup.id,
		slug: page.targetGroup.slug,
		contact: page.targetGroup.contact,
	};

	return (
		<SharedContext
			rolePermission={
				isRole(page.rolePermission) ? page.rolePermission : "guest"
			}
			targetGroup={targetGroupCtx}
			{...sharedProps}
		>
			<AuthGuard
				accessLevels={
					// TODO: update the role names in Dato
					page.rolePermission && isRole(page.rolePermission)
						? [page.rolePermission]
						: ["guest"]
				}
				redirectOnFail
			>
				<Layout
					highlightColor={null}
					header={
						<Header
							breadcrumbsList={breadcrumbsList}
							variant={
								headerShouldTransition
									? "withTransition"
									: undefined
							}
							links={customHeaderLinks}
							logoData={
								page.targetGroup?.header?.headerLogo ??
								undefined
							}
							otherLanguages={otherLanguages}
							hasSiblingBrands={hasSiblingBrands}
						/>
					}
					footer={
						<Footer
							footerSettings={
								sharedProps.siteSettings.footerSettings
							}
						/>
					}
				>
					<SharedPageHead
						metaTags={page._seoMetaTags}
						canonical={canonical}
					/>

					{!hasActualH1 && (
						<VisuallyHidden>
							<h1>{page.seo?.title ?? page.name}</h1>
						</VisuallyHidden>
					)}

					<SectionRenderer sections={page.content} />
				</Layout>
				<EditorTools isPreview={Boolean(isPreview)} />
			</AuthGuard>
		</SharedContext>
	);
};

// 🔬 e2e: pages.spec.ts
