import Head from "next/head";
import { renderMetaTags } from "react-datocms";
import { Tag } from "src/__generated__/cms-schema.codegen";
import { useEnrichedMetaTags } from "src/lib/hooks";

export const SharedPageHead: React.FC<{
	canonical: string;
	metaTags: Array<Tag>;
}> = ({ canonical, metaTags }) => {
	const enrichedMetaTags = useEnrichedMetaTags(metaTags);

	return (
		<Head>
			<meta
				name="viewport"
				data-test
				content="width=device-width, initial-scale=1"
			/>
			<link key="canonical" rel="canonical" href={canonical} />
			<meta
				name="facebook-domain-verification"
				content="ls85jxhr9dw92kwndk4ldvbvq5angb"
			/>

			{renderMetaTags(enrichedMetaTags)}
		</Head>
	);
};

// 🔬 TBD: e2e
