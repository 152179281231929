import { format as pkgFormat } from "date-fns";
import { de } from "date-fns/locale";

export const locale = de;
export const dateFormat = "dd.MM.yyyy";

export const format = (date: number | Date) =>
	pkgFormat(date, dateFormat, { locale });

// 🔬 TBD: Please evaluate
